import React, {useEffect} from 'react';
import {UserContext} from '../contexts/UserContext';
import {setSession} from '../utils/jwt';
import LoadingScreen from './LoadingScreen';
import Router from '../routes';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {BackofficeRoleName, BackofficeRoleScope, getBackofficeUser} from "../api/elton-payments/backoffice";

export const AuthWrapper = () => {
    const {
        user,
        isAuthenticated,
        isLoading,
        login,
        getToken,
        getClaim,
        logout,
    } = useKindeAuth();
    const [userContext, dispatch]: any = React.useContext(UserContext);

    useEffect(() => {
        if (isLoading) {
            // do nothing
        } else if (!isAuthenticated) {
            // When user isn't authenticated, forget any user info
            dispatch({type: 'logout'});
            setSession();
            login()
        } else {
            getToken().then(token => {
                setSession(token)

                const email = getClaim('email')?.value
                if (typeof email !== 'string' || email.indexOf('@') < 0) throw Error('invalid or missing email')

                getBackofficeUser(email).then(user => {
                    const aggregatedRole = user.roles.reduce((acc, curr) => {
                        if (curr.role_scope === BackofficeRoleScope.ADMIN_DASHBOARD) acc.adminDashboardScope = true
                        if (curr.role_name === BackofficeRoleName.EDITOR) acc.editor = true
                        if (curr.role_name === BackofficeRoleName.VIEWER) acc.viewer = true

                        return acc
                    }, {adminDashboardScope: false, editor: false, viewer: false})

                    if (!aggregatedRole.adminDashboardScope) {
                        dispatch({type: 'logout'});
                        setSession();
                        logout()
                        throw Error('missing admin dashboard scope')
                    }

                    if (aggregatedRole.editor) {
                        dispatch({
                            type: 'login',
                            payload: {
                                role: 'Editor',
                                canEdit: true,
                            },
                        });
                    } else if (aggregatedRole.viewer) {
                        dispatch({
                            type: 'login',
                            payload: {
                                role: 'Viewer',
                                canEdit: false,
                            },
                        });
                    } else {
                        dispatch({type: 'logout'});
                        setSession();
                        logout()
                        throw Error('missing valid role')
                    }
                })
            });
        }
    }, [user, isLoading, isAuthenticated, dispatch]);

    return isLoading || !isAuthenticated ? <LoadingScreen/> : <Router/>;
};
